<template>
  <div>
    <header-slot no-border-bottom>
      <template #actions>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="hideFirstTab"
            class="mr-1 btn-back"
            variant="primary"
            :to="{ name: $route.meta.employeeClaimsDashboardRoute }"
          >
            <feather-icon icon="ArrowLeftIcon" size="15" />
            <span> Back to Dashboard</span>
          </b-button>
          <b-button
            v-if="!$route.meta.tabEmployeeClaimListRoute"
            variant="success"
            @click="openModalCreateEmployeeClaim"
            class="btn-black"
          >
            <feather-icon icon="PlusIcon" size="15" />
            <span> New Claim</span>
          </b-button>
        </div>
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0" v-if="!hideFirstTab">
      <b-nav-item
        v-if="
          [17, 19].includes(currentUser.modul_id) ||
          [1, 2, 17].includes(currentUser.role_id)
        "
        :to="{ name: $route.meta.tabOthersClaimsRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        :active="$route.matched[2].name == $route.meta.tabOthersClaimsRoute"
        >{{
          [17, 19].includes(currentUser.modul_id)
            ? "Other Claims"
            : "All Claims"
        }}
        <span class="pl-2">
          <feather-icon
            icon
            :badge="
              CounterEmployee.counterModule > 99
                ? '99+'
                : CounterEmployee.counterModule
            "
            badge-classes="bg-danger"
          /> </span
      ></b-nav-item>
      <b-nav-item
        :to="{ name: $route.meta.tabEmployeeClaimListRoute }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        >My Claims
        <span class="ml-1">
          <feather-icon
            icon
            :badge="
              CounterEmployee.counterUser > 99
                ? '99+'
                : CounterEmployee.counterUser
            "
            badge-classes="bg-danger"
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card
      class="border-top-primary border-3 border-table-radius px-0"
      no-body
      v-if="!hideFirstTab"
    >
      <router-view :key="$route.name" />
    </b-card>
    <router-view :key="$route.name" v-else />
    <ModalCreateClaimRequest
      v-if="showModalCreateClaimRequest"
      @close="closeModalCreateClaimRequest"
    />
  </div>
</template>
<script>
import ModalCreateClaimRequest from "@/views/commons/employee-claims/components/modal/ModalCreateEmployeeClaim.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalCreateClaimRequest,
  },
  data() {
    return {
      showModalCreateClaimRequest: false,
    };
  },
  methods: {
    openModalCreateEmployeeClaim() {
      this.showModalCreateClaimRequest = true;
    },
    closeModalCreateClaimRequest() {
      this.showModalCreateClaimRequest = false;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    hideFirstTab() {
      return [17, 16, 19].includes(this.moduleId);
    },
  },
};
</script>

<style scoped>
.content-header-right {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss">
.btn-back {
  align-items: center;
  display: flex;
  gap: 5px;
}
.btn-black {
  display: flex;
  align-items: center;
  gap: 5px;
  svg {
    color: #111013 !important;
  font-weight: 500;
  }
  span {
    color: #111013 !important;
    font-weight: 500;
  }
}
</style>