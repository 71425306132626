import { amgApi } from "@/service/axios";

class ClaimTypesService{

    async getClaimTypes(module_id){
        const data = await amgApi.get(`/management/claims/types/get-claim-types`)
        return data;
    }
}

export default new ClaimTypesService()