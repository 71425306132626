var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-slot',{attrs:{"no-border-bottom":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.hideFirstTab)?_c('b-button',{staticClass:"mr-1 btn-back",attrs:{"variant":"primary","to":{ name: _vm.$route.meta.employeeClaimsDashboardRoute }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"15"}}),_c('span',[_vm._v(" Back to Dashboard")])],1):_vm._e(),(!_vm.$route.meta.tabEmployeeClaimListRoute)?_c('b-button',{staticClass:"btn-black",attrs:{"variant":"success"},on:{"click":_vm.openModalCreateEmployeeClaim}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"15"}}),_c('span',[_vm._v(" New Claim")])],1):_vm._e()],1)]},proxy:true}])}),(!_vm.hideFirstTab)?_c('b-nav',{staticClass:"m-0",attrs:{"card-header":"","pills":""}},[(
        [17, 19].includes(_vm.currentUser.modul_id) ||
        [1, 2, 17].includes(_vm.currentUser.role_id)
      )?_c('b-nav-item',{attrs:{"to":{ name: _vm.$route.meta.tabOthersClaimsRoute },"exact":"","exact-active-class":"active","link-classes":['px-3', _vm.bgTabsNavs],"active":_vm.$route.matched[2].name == _vm.$route.meta.tabOthersClaimsRoute}},[_vm._v(_vm._s([17, 19].includes(_vm.currentUser.modul_id) ? "Other Claims" : "All Claims")+" "),_c('span',{staticClass:"pl-2"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.CounterEmployee.counterModule > 99
              ? '99+'
              : _vm.CounterEmployee.counterModule,"badge-classes":"bg-danger"}})],1)]):_vm._e(),_c('b-nav-item',{attrs:{"to":{ name: _vm.$route.meta.tabEmployeeClaimListRoute },"exact":"","exact-active-class":"active","link-classes":['px-3', _vm.bgTabsNavs]}},[_vm._v("My Claims "),_c('span',{staticClass:"ml-1"},[_c('feather-icon',{attrs:{"icon":"","badge":_vm.CounterEmployee.counterUser > 99
              ? '99+'
              : _vm.CounterEmployee.counterUser,"badge-classes":"bg-danger"}})],1)])],1):_vm._e(),(!_vm.hideFirstTab)?_c('b-card',{staticClass:"border-top-primary border-3 border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name})],1):_c('router-view',{key:_vm.$route.name}),(_vm.showModalCreateClaimRequest)?_c('ModalCreateClaimRequest',{on:{"close":_vm.closeModalCreateClaimRequest}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }