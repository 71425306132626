<template>
  <b-modal
    v-model="showModal"
    size="lg"
    :title="'Create Employee Claim'"
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    scrollable
    @hidden="onCloseModal"
  >
    <template #modal-header>
      <h5 class="modal-title h4 text-white">Create Employee Claim</h5>

      <button
        type="button"
        aria-label="Close"
        class="close"
        @click="onCloseModal"
      >
        ×
      </button>
    </template>

    <validation-observer ref="form">
      <b-row class="mt-1">
        <!-- <b-col cols="12" md="12" sm="12" lg="4">
          <validation-provider
            v-slot="{ errors }"
            name="Department"
            rules="required"
          >
            <b-form-group label="Department" :state="errors[0] ? false : null">
              <v-select
                id="departments"
                v-model="form.module_id"
                :options="modules"
                :clearable="false"
                label="label"
                class="w-100"
                @input="onChangeDepartment"
                :reduce="(option) => option.id"
              />
            </b-form-group>
          </validation-provider>
        </b-col> -->

        <b-col cols="6" md="12" sm="12" lg="6">
          <validation-provider
            v-slot="{ errors }"
            name="claim_type"
            rules="required"
          >
            <b-form-group label="Claim Type" :state="errors[0] ? false : null">
              <v-select
                id="claim_types"
                v-model="form.claim_type_id"
                :options="claim_types"
                label="description"
                class="w-100"
                :reduce="(option) => option.id"
                :getOptionLabel="(option) => option.description"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6" md="12" sm="12" lg="6">
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required|max:50"
          >
            <b-form-group label="Title">
              <b-form-input
                maxlength="50"
                v-model="form.title"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            v-slot="{ errors }"
            name="Description"
            rules="required"
          >
            <b-form-group label="Description">
              <b-form-textarea
                id="description"
                rows="4"
                no-resize
                v-model.trim="form.description"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Files">
            <DragAndDropFiles
              v-model="form.files"
              :files-array="form.files"
              :single="false"
              :image="false"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" v-if="form.loadedFiles.length > 0">
          <b-form-group label="Loaded Files">
            <b-row>
              <b-col
                cols="2"
                v-for="(file, index) in form.loadedFiles"
                :key="index"
              >
                <b-button-group>
                  <b-button
                    variant="outline-primary"
                    class="text-left"
                    @click="openFile(file)"
                  >
                    <feather-icon icon="FileIcon" class="mr-1" />
                    <span class="text-truncate">{{ file.name }}</span>
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="text-center px-0"
                    @click="removeEmployeeClaimFile(file.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      class="text-danger cursor-pointer ml-1 mx-1"
                    />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="my-1">
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button variant="outline-danger" class="mr-2" @click="onCloseModal">
            <feather-icon icon="XIcon" class="mr-1" />
            CANCEL
          </b-button>
          <b-button
            variant="primary"
            type="submit"
            @click="saveOrUpdateEmployeeClaim(EmployeeClaimStatus.PENDING)"
            ><feather-icon icon="SaveIcon" class="mr-1" />SEND CLAIM</b-button
          >
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

// Data
import modules from "@/views/commons/employee-claims/data/departments.js";
import EmployeeClaimStatus from "@/views/commons/employee-claims/data/employee-claim-status.js";
// Services
import ClaimTypesService from "@/views/commons/employee-claims/services/claim_types.service.js";
import EmployeeClaimService from "@/views/commons/employee-claims/services/employee-claims.service.js";
// components
import DragAndDropFiles from "@/views/commons/utilities/DragAndDrop.vue";

export default {
  components: {
    DragAndDropFiles,
  },
  props: {
    loadDraftOnMount: {
      type: Boolean,
      default: false,
    },
    draftId: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      showModal: true,
      form: {
        id: null,
        claim_type_id: null,
        module_id: null,
        title: "",
        files: [],
        loadedFiles: [],
        in_schedule: 1,
        status: EmployeeClaimStatus.PENDING,
      },
      modules: modules,
      claim_types: [],
      module_to_claim: null,
    };
  },
  async mounted() {
    if (this.loadDraftOnMount === true) {
      await this.loadDraft();
    }
  },
  async created() {
    this.form.module_id = this.modules[0].id;
    await this.getClaimTypes();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    EmployeeClaimStatus() {
      return EmployeeClaimStatus;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE_EMPLOYEE_CLAIMS:
        "EmployeeClaimsStore/A_UPDATE_TABLE_EMPLOYEE_CLAIMS",
    }),
    ...mapMutations({
      setEmplooyeId: "ManagementEmployeesStore/M_SET_S_EMPLOYEE_ID",
      updateCount: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    async onCloseModal() {
      await this.askToSaveAsDraft();
    },
    async askToSaveAsDraft() {
      if (!this.form.id) {
        const keys = Object.keys(this.form);
        const excludeKeys = ["module_id", "status", "in_schedule", "id"];
        const filteredKeys = keys.filter((key) => !excludeKeys.includes(key));

        for (const key of filteredKeys) {
          if (this.form[key] != null && this.form[key] != "") {
            const result = await this.showConfirmSwal(
              "Do you want to save as draft?",
              "You have unsaved changes"
            );
            if (result.isConfirmed) {
              await this.saveAsDraft();
            }
            break;
          }
        }
        this.$emit("close");
      } else {
        this.$emit("close");
      }
    },

    async saveAsDraft() {
      await this.saveOrUpdateEmployeeClaim(EmployeeClaimStatus.CREATED);
    },

    /**
     * return true if employee is in schedule
     */
    async setInSchedule() {
      try {
        let params = {
          module_to_claim: this.module_to_claim,
          user_id: this.currentUser.user_id,
        };
        const { data } = await EmployeeClaimService.validateEmployeeSchedule(
          params
        );
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    onChangeDepartment(module_to_claim) {
      this.module_to_claim = module_to_claim;
      this.form.claim_type_id = null;
      this.getClaimTypes(module_to_claim);
    },
    openFile(file) {
      window.open(file.path, "_blank");
    },

    async getClaimTypes(moduleId = null) {
      let module = moduleId == null ? this.form.module_id : moduleId;
      this.module_to_claim = module;
      try {
        const { data, status } = await ClaimTypesService.getClaimTypes(module);
        if (status === 200) {
          this.claim_types = data.claimTypes;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async saveOrUpdateEmployeeClaim(_EmployeeClaimStatus) {
      try {
        if (
          _EmployeeClaimStatus !== EmployeeClaimStatus.CREATED ||
          _EmployeeClaimStatus === EmployeeClaimStatus.PENDING
        ) {
          const isValid = await this.$refs.form.validate();
          if (!isValid) return;
        }
        this.addPreloader();



        let form = new FormData();
        form.append("module_id", this.moduleId);
        form.append("created_by", this.currentUser.user_id);
        form.append(
          "claim_type_id",
          this.form.claim_type_id == null ? 0 : this.form.claim_type_id
        );
        form.append("title", this.form.title ?? null);
        form.append("description", this.form.description ?? null);
        form.append("status", _EmployeeClaimStatus);
        form.append("in_schedule", this.form.in_schedule);
        form.append("user_id", this.currentUser.user_id);
        form.append("id", this.form.id);

        if (this.form.files.length > 0) {
          this.form.files.forEach((file) => {
            form.append("files[]", file, file.name);
          });
        }
        const { data, status } =
          await EmployeeClaimService.saveOrUpdateEmployeeClaim(form);
        if (status === 200) {
          this.A_UPDATE_TABLE_EMPLOYEE_CLAIMS(true);
          this.showToast("success", "top-right", data.message, "CheckIcon");
        }
        this.removePreloader();
        this.$emit("close");
      } catch (error) {
        this.removePreloader();
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          error.response.data.message
        );
      }
    },

    async loadDraft() {
      console.log(this.draftId);
      try {
        const { data, status } = await EmployeeClaimService.getEmployeeClaim({
          user_id: this.currentUser.user_id,
          status: EmployeeClaimStatus.CREATED,
          claim_request_id: this.draftId,
        });
        if (status === 200 && data.message === "Claim request not found") {
          return;
        }
        if (status === 200) {
          this.form = data.claim_request;
          this.form.module_id = parseInt(
            data.claim_request.department_selected_from_claim_type
          );

          this.form.files = []; // reset files
          if (data.claim_request.files) {
            this.form.loadedFiles = data.claim_request.files;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async removeEmployeeClaimFile(fileId) {
      try {
        const { data, status } =
          await EmployeeClaimService.removeEmployeeClaimFile({ fileId });
        if (status === 200) {
          this.showToast("success", "top-right", data.message, "CheckIcon");
          await this.loadDraft();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped>
.floating-right-badge {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
}
</style>